// client/src/components/CoachList.js
import React, { useState, useEffect } from 'react';
import { getAllCoaches } from '../services/api';
import FinanceExpertImage from '../assets/finance.jpg'; // Make sure you have an image in this path


const FinanceHome = () => {
    const handleButtonClick = () => {
      window.location.href = 'https://t.me/+TuuTld6cGdY2MDU1';
    };
  
    return (
      <div>
        <div className="bg-rose-500 px-4 py-5">
            <h1 className="text-4xl font-bold text-white">Boolean Coaching</h1>
        </div>
        <div className="">
          <section className="flex flex-col md:flex-row items-center justify-between text-left max-w-7xl mx-auto mt-20">
            <div className="md:w-1/2">
              <h1 className="text-4xl font-bold mb-4 text-gray-900">Find and Connect with Your Coach Here</h1>
              <p className="text-2xl mb-5 text-gray-700">Find your gym, career, love and other coaches here!</p>
              <button 
                onClick={handleButtonClick}
                className="bg-rose-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 text-xl"
              >
                Get Started
              </button>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
              <img src={FinanceExpertImage} alt="Personal Finance Expert" className="rounded-lg shadow-lg" />
            </div>
          </section>
        </div>
      </div>
    );
  };
  
  export default FinanceHome;
  
  