import axios from 'axios';

const BASE_URL = 'https://soaiapp.com/api';

const api = axios.create({
  baseURL: BASE_URL,
});

export const getAllCoaches = async () => {
  try {
      const response = await api.get('/coaches');
      return response.data;
  } catch (error) {
      console.error('Error fetching coaches:', error);
      throw error;
  }
};
